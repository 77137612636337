import { monthsName } from '../widgetTopDownBottomUp/common'

const monthRange = [
  ['APR', 'MAY', 'JUN'],
  ['JUL', 'AUG', 'SEP'],
  ['OCT', 'NOV', 'DEC'],
  ['JAN', 'FEB', 'MAR'],
]

const quaters = ['Q1', 'Q2', 'Q3', 'Q4']

export const firstSetHeader = [
  { title: 'Segment', value: 'Segment' },
  { title: 'Account Code', value: 'acc_code' },
  { title: 'ACCOUNT NAME', value: 'acc_name' },
  { title: 'ACCOUNT MANAGER', value: 'acc_manager' },
  { title: 'CIRCLE/VERTICAL', value: 'circle_name' },
]

export const secondSetRowOneHeader = months => {
  return [
    { title: 'REVENUE IN CR.', colspan: months },
    { title: 'MOBILITY', colspan: 29 * months }, //36
    { title: 'IOT', colspan: 8 * months },
    { title: 'FLD', colspan: 10 * months },
    { title: 'FLV', colspan: 7 * months },
    { title: 'CLOUD', colspan: 7 * months },
    { title: 'COLO', colspan: 4 * months },
  ]
}

export const secondSetRowTwoHeader = totalMonths => {
  return [
    { title: 'Total Billing', colspan: totalMonths },
    // Mobility
    { title: 'Mob - Gross Net (in Nos)', colspan: 3 * totalMonths },
    { title: 'Mob - Churn (in Nos)', colspan: 3 * totalMonths },
    { title: 'Mob - Net Adds (in Nos)', colspan: 3 * totalMonths },
    {
      title: 'Mob - Sub Base (Active) (in Nos)',
      colspan: 3 * totalMonths,
    },
    {
      title: 'Mob - Sub Base (Suspended) (in Nos)',
      colspan: 3 * totalMonths,
    },
    {
      title: 'Mob - Billed Net Amt (W/o Tax) (Rs. Cr)',
      colspan: 3 * totalMonths,
    },
    {
      title: 'Mob - Adjustments (Rs. Cr)',
      colspan: totalMonths,
    },
    // { title: 'Waivers', colspan: totalMonths },
    { title: 'Mob - Outstanding Amt (Rs. Cr)', colspan: totalMonths },
    {
      title: 'Mob - Billed ARPU (in Rs)',
      colspan: 3 * totalMonths,
    },
    {
      title: 'Mob - 4G Handset Base (in Nos)',
      colspan: 3 * totalMonths,
    },
    // {
    //   title: 'OG MoUs (in Nos)',
    //   colspan: 3 * totalMonths,
    // },
    // {
    //   title: 'Data Usage (Mb)',
    //   colspan: 3 * totalMonths,
    // },
    {
      title: 'Mob - Data Usage  (Mb)/ Sub',
      colspan: 3 * totalMonths,
    },
    // IOT
    { title: 'IOT - Gross Net (in Nos)', colspan: totalMonths },
    { title: 'IOT - Churn (in Nos)', colspan: totalMonths },
    { title: 'IOT - Net adds (in Nos)', colspan: totalMonths },
    { title: 'IOT - Active Subs Base (in Nos)', colspan: totalMonths },
    {
      title: 'IOT - Suspended Subs Base (in Nos)',
      colspan: totalMonths,
    },
    {
      title: 'IOt - Billed Net Amt (W/o Tax) (Rs. Cr)',
      colspan: totalMonths,
    },
    // { title: 'Waivers', colspan: totalMonths },
    { title: 'IOT - Adjustments (Rs. Cr)', colspan: totalMonths },
    { title: 'IOT - Outstanding Amt (Rs. Cr)', colspan: totalMonths },
    // FLD
    { title: 'FLD - OB (Rs. Cr)', colspan: totalMonths },
    { title: 'FLD - OB No. of Orders (in Nos)', colspan: totalMonths },
    { title: 'FLD - Churn AOV (Rs. Cr)', colspan: totalMonths },
    { title: 'FLD - Churn No. of Links (Count)', colspan: totalMonths },
    { title: 'FLD - Commisioning (Rs. Cr)', colspan: totalMonths },
    { title: 'FLD - Active Links (in Nos)', colspan: totalMonths },
    { title: 'FLD - Suspended Links (in Nos)', colspan: totalMonths },
    {
      title: 'FLD - Billed Net Amt (W/o Tax) (Rs. Cr)',
      colspan: totalMonths,
    },
    // { title: 'Waivers', colspan: totalMonths },
    { title: 'FLD - Adjustments (Rs. Cr)', colspan: totalMonths },
    { title: 'FLD - Outstanding Amt (Rs. Cr)', colspan: totalMonths },
    // FLV
    { title: 'FLV - OB (Rs. Cr)', colspan: totalMonths },
    { title: 'FLV - OB No. of Orders', colspan: totalMonths },
    { title: 'FLV - Active Links', colspan: totalMonths },
    { title: 'FLV - Suspended Links', colspan: totalMonths },
    { title: 'FLV - Billed Net Amt (W/o Tax)', colspan: totalMonths },
    // { title: 'Waivers', colspan: totalMonths },
    { title: 'FLV - Adjustments', colspan: totalMonths },
    { title: 'FLV - Outstanding Amt', colspan: totalMonths },
    // CLOUD
    { title: 'Cloud - Cloud Gross ( Lic.)', colspan: totalMonths },
    { title: 'Cloud - Cloud Churn ( Lic.)', colspan: totalMonths },
    { title: 'Cloud - Cloud Net ( Lic.)', colspan: totalMonths },
    { title: 'Cloud - Cloud OB ( In Rs Cr.)', colspan: totalMonths },
    {
      title: 'Cloud - Billed Net Amt (W/o Tax) (Rs. Cr)',
      colspan: totalMonths,
    },
    // { title: 'Waivers', colspan: totalMonths },
    { title: 'Cloud - Adjustments (Rs. Cr)', colspan: totalMonths },
    { title: 'Cloud - Outstanding Amt (Rs. Cr)', colspan: totalMonths },
    // CTLRS OB
    { title: 'COLO - COLO OB (Rs. Cr)', colspan: totalMonths },
    {
      title: 'COLO - Billed Net Amt (W/o Tax) (Rs. Cr)',
      colspan: totalMonths,
    },
    // { title: 'Waivers', colspan: totalMonths },
    { title: 'COLO - Adjustments (Rs. Cr)', colspan: totalMonths },
    { title: 'COLO - Outstanding Amt (Rs. Cr)', colspan: totalMonths },
  ]
}

export const secondSetRowThirdHeader = totalMonths => {
  return [
    { title: 'All Products', colspan: totalMonths },
    ...Array(18)
      .fill()
      .map((item, i) => {
        if (i % 3 === 0) {
          return { title: 'COCP', colspan: totalMonths }
        } else if (i % 3 === 1) {
          return { title: 'IOIP', colspan: totalMonths }
        } else {
          return { title: 'Total', colspan: totalMonths }
        }
      }),
    ...Array(1)
      .fill()
      .map(i => ({ title: 'COCP', colspan: totalMonths })),
    ...Array(1)
      .fill()
      .map(i => ({ title: 'OVERALL', colSpan: totalMonths })),
    ...Array(15)
      .fill()
      .map((item, i) => {
        if (i % 3 === 0) {
          return { title: 'COCP', colspan: totalMonths }
        } else if (i % 3 === 1) {
          return { title: 'IOIP', colspan: totalMonths }
        } else {
          return { title: 'Total', colspan: totalMonths }
        }
      }),
    ...Array(7)
      .fill()
      .map(i => ({ title: 'Overall', colspan: totalMonths })),
    { title: 'OVERALL', colspan: totalMonths },
    ...Array(22) //28
      .fill()
      .map(i => ({ title: 'Overall', colspan: totalMonths })),
  ]
}

export const secondSetRowFourthHeader = (
  totalMonths,
  periodValue,
  periodTypeValue
) => {
  let arr = []
  let months = []
  if (totalMonths > 1) {
    if (periodTypeValue === 17) {
      months = quaters
    } else if (periodTypeValue === 6) {
      for (let i = 0; i < 3; i++) {
        if (periodValue - i >= 1) {
          months.push(monthsName[periodValue - i]?.toUpperCase())
        } else {
          months.push(monthsName[periodValue + (12 - i)]?.toUpperCase())
        }
      }
      months.reverse()
    } else {
      months = monthRange[periodValue - 1]
    }
  } else {
    months = [monthsName[periodValue]?.toUpperCase()]
  }
  let obj = {}
  ;[
    'total_billed_amt',
    'gross_net_cocp_mob',
    'gross_net_ioip_mob',
    'gross_net_total_mob',
    'churn_cocp_mob',
    'churn_ioip_mob',
    'churn_total_mob',
    'netadds_cocp_mob',
    'netadds_ioip_mob',
    'netadds_total_mob',
    'sub_base_active_cocp_mob',
    'sub_base_active_ioip_mob',
    'sub_base_active_total_mob',
    'sub_base_suspended_cocp_mob',
    'sub_base_suspended_ioip_mob',
    'sub_base_suspended_total_mob',
    'billed_net_amt_cocp_mob',
    'billed_net_amt_ioip_mob',
    'billed_net_amt_total_mob',
    'adjustment_cocp_mob',
    // 'waivers_mob',
    'outstanding_amt_total_mob',
    'billed_arpu_cocp_mob',
    'billed_arpu_ioip_mob',
    'billed_arpu_total_mob',
    'handset_fourg_cocp_mob',
    'handset_fourg_ioip_mob',
    'handset_fourg_total_mob',
    // 'og_mous_cocp_mob',
    // 'og_mous_ioip_mob',
    // 'og_mous_total_mob',
    // 'data_usage_cocp_mob',
    // 'data_usage_ioip_mob',
    // 'data_usage_total_mob',
    'data_usage_mb_cocp_mob',
    'data_usage_mb_ioip_mob',
    'data_usage_mb_total_mob',
    'gross_net_total_iot',
    'churn_total_iot',
    'netadds_total_iot',
    'sub_base_active_total_iot',
    'sub_base_suspended_total_iot',
    'billed_net_amt_total_iot',
    // 'waivers_iot',
    'adjustment_total_iot',
    'outstanding_amt_total_iot',
    'ob_in_cr_fld',
    'ob_in_order_fld',
    'churn_aov_in_cr_fld',
    'churn_no_of_links_fld',
    'commisioning_fld',
    'active_link_fld',
    'suspended_link_fld',
    'billed_net_amount_fld',
    // 'waviers_fld',
    'adjustments_fld',
    'outstanding_amt_fld',
    'ob_in_cr_flv',
    'ob_in_order_flv',
    'active_link_flv',
    'suspended_link_flv',
    'billed_net_amount_flv',
    // 'waviers_flv',
    'adjustments_flv',
    'outstanding_amt_flv',
    'cloud_gross_in_lic',
    'cloud_churn_in_lic',
    'cloud_net_in_lic',
    'cloud_ob_in_cr',
    'cloud_billed_net_amount',
    // 'cloud_waviers',
    'cloud_adjustments',
    'cloud_outstanding_amt',
    'ctrls_ob_in_cr',
    'ctrls_billed_net_amount',
    // 'ctrls_waviers',
    'ctrls_adjustments',
    'ctrls_outstanding_amt',
  ].forEach(key => {
    months.forEach(mnth => {
      arr.push({ title: mnth, colspan: 1, value: `${key}_${mnth}` })
    })
  })
  return arr
}
