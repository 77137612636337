import React from 'react'
import { Box } from '@chakra-ui/react'
import { useHistory } from 'react-router-dom'
import groupBy from 'lodash/groupBy'
import { RenderShortcutItem } from './renderShortcutItem'

const DashboardShortcutData = ({ data }) => {
	const history = useHistory()
	const onDashboardClick = ele => {
		history.push(
			`/dashboard/${JSON.stringify({
				value: ele.sub_application_id,
				label: ele.sub_application_name,
				app_name: ele.application_name,
				app_id: ele.application_id,
				is_card_layout_enabled: !!ele?.is_card_layout_enabled
					? ele?.is_card_layout_enabled
					: 0,
			})}`
		)
	}

	const renderGroupBy = () => {
		const render = []
		const groupByData = groupBy(data, 'application_id')
		if (!!groupByData && Object.keys(groupByData).length > 0) {
			Object.keys(groupByData).forEach(key => {
				const flexItems = groupByData[key].map(item => {
					return (
						<RenderShortcutItem
							item={item}
							onDashboardClick={onDashboardClick}
						/>
					)
				})
				render.push(
					<Box border='1px solid #98999c' padding='8px' borderRadius='8px'>
						{flexItems}
					</Box>
				)
			})
		}
		return render
	}

	const groupByData = groupBy(data, 'application_id')
	const fr = Object.keys(groupByData).reduce((a, s) => a + '1fr ', '')

	return (
		<>
			<Box
				w='100%'
				display='grid'
				gridTemplateColumns={fr}
				gridColumnGap='1rem'
				alignItems='start'
				mr='1rem'
				maxHeight={`calc(100vh - 346px)`}
				overflowY={'auto'}
				overflowX={'auto'}
				padding={'1rem'}
				backgroundColor='white'
				borderRadius={'md'}
			>
				{renderGroupBy()}
			</Box>
		</>
	)
}

export default DashboardShortcutData
