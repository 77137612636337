import React, { useEffect, useState } from 'react'
import { loadAwsFile } from './../../../../utils/aws.util'
import { Text, Flex, Image, Spinner, Tooltip } from '@chakra-ui/react'

const RenderShortcutItem = ({ item, onDashboardClick }) => {
	const [imageData, setImageData] = useState(null)
	const [isLoading, setIsLoading] = useState(false)

	useEffect(() => {
		if (!!item.icon_url) setIsLoading(true)
		setTimeout(() => {
			if (!!item.icon_url) {
				loadAwsFile(item.icon_url, d => {
					let data = 'data:' + d.type + ';base64,' + d.data
					setImageData(data)
					setIsLoading(false)
				})
			}
		}, 1000)
	}, [item.icon_url])

	const renderLogo = () => {
		if (!!item.icon_url) {
			if (isLoading) return <Spinner h='32px' w='32px' color='brand.800' />
			else
				return (
					<Image
						h='32px'
						w='32px'
						objectFit='contain'
						alt='logo'
						src={imageData}
					/>
				)
		}
	}

	return (
		<>
		 <Tooltip
              label={item.description}
              fontSize="sm"
              bg="secondary"
              color="black.700"
            >
			<Flex
				key={item.sequence_no}
				flex={1}
				borderRadius='md'
				boxShadow='md'
				bg='white'
				p={4}
				mb='1rem'
				cursor='pointer'
				onClick={() => {
					onDashboardClick(item)
				}}
				justifyContent='center'
				alignItems='center'
				flexDirection='column'
				_hover={{
					color: 'white',
					bg: localStorage.getItem('color'),
				}}
				className='card-text'
			>
				{renderLogo()}
				<Text
					fontWeight={800}
					my={0}
					fontSize='md'
					textAlign='center'
					color='brand.800'
				>
					{item.sub_application_name}
				</Text>
			</Flex>
			</Tooltip>
		</>
	)
}

export { RenderShortcutItem }
