/* eslint-disable no-self-compare */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Skeleton,
  Stack,
  Text,
  Icon,
  Tooltip,
  Flex,
  VStack,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import DrillDownTable, {
  TH,
  TR,
  TD,
} from '../../../../components/dashboard-table'
import { BiDownload } from 'react-icons/bi'
import { useDashboardContext } from '../../../../context/dashboard.context'
import { useGetAccountsView } from '../../../../hooks/dashboard.hooks'
import { dashboardConst } from '../../../../utils/action.constant'
import { calculateStartEndDate } from '../../../../utils/dashboard.utils'
import { monthsName, noOfmonths } from '../widgetTopDownBottomUp/common'
import { useAuthContext } from '../../../../context/auth.context'

import ReactExport from 'react-data-export'
import { numberWithCommas } from '../../../../utils/common.util'
import { getUTCtoIST } from '../../../../utils/date.utils'
import {
  firstSetHeader,
  secondSetRowOneHeader,
  secondSetRowTwoHeader,
  secondSetRowThirdHeader,
  secondSetRowFourthHeader,
} from './headerConst'
import { PropagateLoader } from 'react-spinners'

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet

let monthRange = [
  ['APR', 'MAY', 'JUN'],
  ['JUL', 'AUG', 'SEP'],
  ['OCT', 'NOV', 'DEC'],
  ['JAN', 'FEB', 'MAR'],
]

let quaters = ['Q1', 'Q2', 'Q3', 'Q4']

function AccountView() {
  const {
    state: { queryFilters, accounts_view },
    dispatch: dashboardDispatch,
  } = useDashboardContext()
  const { mutate: getAccView } = useGetAccountsView()
  const {
    state: { authData, isBottomChg },
  } = useAuthContext()

  const [lastUpdate, setLastUpdate] = useState('')
  const [formData, setFormData] = useState([])
  const [pageCount, setpageCount] = useState(0)
  const [isLoading, setisLoading] = useState(false)
  const [scrollAtBottom, setScrollAtBottom] = useState(false)

  const getData = (prevData = {}, startFrom = 0) => {
    setisLoading(true)
    const {
      selectedPeriod,
      selectedPeriodType,
      selectedYear,
      selectedCreator,
    } = queryFilters
    let { startDate, endDate } = calculateStartEndDate({
      selectedPeriod,
      selectedPeriodType,
      selectedYear,
    })
    let limitValue = 1000
    getAccView(
      {
        asset_id: selectedCreator?.value || 0,
        auth_asset_id: authData.asset_id,
        asset_token_auth: authData.asset_token_auth,
        asset_image_path: '',
        device_os_id: 5,
        service_version: 0.1,
        app_version: 1,
        month: '',
        year: selectedYear?.value?.replaceAll(' ', '') || '',
        start_date: startDate,
        end_date: endDate,
        quarter: selectedPeriod?.label.split(' ')[0] || '',
        flag: selectedPeriodType?.label === 'YTD' ? 1 : 0,
        start_from: startFrom,
        limit_value: limitValue,
      },
      {
        onSuccess: data => {
          // if (data.length === limitValue) {
          //   getData([...prevData, ...data], startFrom + 1);
          // } else {
          //   if (data.length)
          //     setLastUpdate(getUTCtoIST(data[0].last_updated_date));
          //   else setLastUpdate('');
          //   if (Array.isArray(data)) dispatchAccData(data);
          // }
          let finalData = Object.keys(prevData).length
            ? [...formData, ...data]
            : [...data]
          setFormData(finalData)
          if (data.length)
            setLastUpdate(getUTCtoIST(data[0].last_file_updated_date))
          else setLastUpdate('')
          if (Array.isArray(data)) {
            dispatchAccData(finalData)
          }
          setisLoading(false)
        },
        onError: err => {
          toast({
            title: `${err.message}`,
            position: `top-right`,
            isClosable: true,
            status: 'error',
          })
          setisLoading(false)
        },
      }
    )
  }

  function dispatchAccData(data) {
    dashboardDispatch({
      type: dashboardConst.ADD_ACCOUNT_VIEWS,
      payload: data,
    })
  }

  useEffect(() => {
    getData()
    dispatchAccData([])
    setFormData([])
  }, [
    queryFilters.selectedPeriod,
    queryFilters.selectedPeriodType,
    queryFilters.selectedYear,
    queryFilters.selectedCreator,
  ])
  useEffect(() => {
    setpageCount(formData.length)
    setisLoading(true)
    if (!isLoading && formData.length > 0) {
      let count = formData.length
      setpageCount(formData.length)
      getData(accounts_view, count)
    }
  }, [isBottomChg])

  const renderHeadings = () => {
    let totalMonths = 1
    if (queryFilters.selectedPeriodType?.value === 17) {
      totalMonths = 4
    } else {
      totalMonths = noOfmonths[queryFilters.selectedPeriodType?.value] || 1
    }
    let firstHeader = [
      { label: 'Segment', rowSpan: 4, colSpan: 1, className: 'bg_1' },
      {
        label: 'Account Code',
        rowSpan: 4,
        colSpan: 1,
        minWidth: 200,
        className: 'bg_2',
      },
      {
        label: 'Account Name',
        rowSpan: 4,
        colSpan: 1,
        minWidth: 400,
        className: 'bg_3',
      },
      {
        label: 'Account Manager',
        rowSpan: 4,
        colSpan: 1,
        minWidth: 200,
        className: 'bg_4',
      },
      { label: 'Circle/Vertical', rowSpan: 4, colSpan: 1, className: 'bg_5' },
      {
        label: 'Revenue in Cr.',
        rowSpan: 1,
        colSpan: totalMonths,
        className: 'bg_6',
      },
      {
        label: 'Mobility',
        rowSpan: 1,
        colSpan: 29 * totalMonths,
        className: 'bg_7',
      }, //35* totalMonth
      { label: 'IOT', rowSpan: 1, colSpan: 8 * totalMonths, className: 'bg_8' },
      {
        label: 'FLD',
        rowSpan: 1,
        colSpan: 10 * totalMonths,
        className: 'bg_9',
      },
      {
        label: 'FLV',
        rowSpan: 1,
        colSpan: 7 * totalMonths,
        className: 'bg_10',
      },
      {
        label: 'Cloud',
        rowSpan: 1,
        colSpan: 7 * totalMonths,
        className: 'bg_11',
      },
      {
        label: 'COLO',
        rowSpan: 1,
        colSpan: 4 * totalMonths,
        className: 'bg_12',
      },
    ]
    let secondHeader = [
      { label: 'Total Billing', rowSpan: 1, colSpan: totalMonths },
      // mobility
      {
        label: 'Mob - Gross Net (in Nos)',
        rowSpan: 1,
        colSpan: 3 * totalMonths,
      },
      { label: 'Mob - Churn (in Nos)', rowSpan: 1, colSpan: 3 * totalMonths },
      {
        label: 'Mob - Net Adds (in Nos)',
        rowSpan: 1,
        colSpan: 3 * totalMonths,
      },
      {
        label: 'Mob - Sub Base (Active) (in Nos)',
        rowSpan: 1,
        colSpan: 3 * totalMonths,
      },
      {
        label: 'Mob - Sub Base (Suspended) (in Nos)',
        rowSpan: 1,
        colSpan: 3 * totalMonths,
      },
      {
        label: 'Mob - Billed Net Amt (W/o Tax) (Rs. Cr)',
        rowSpan: 1,
        colSpan: 3 * totalMonths,
      },
      {
        label: 'Mob - Adjustments (Rs. Cr)',
        rowSpan: 1,
        colSpan: totalMonths,
      },
      // { label: 'Waivers', rowSpan: 1, colSpan: totalMonths },
      {
        label: 'Mob - Outstanding Amt (Rs. Cr)',
        rowSpan: 1,
        colSpan: totalMonths,
      },
      {
        label: 'Mob - Billed ARPU (in Rs)',
        rowSpan: 1,
        colSpan: 3 * totalMonths,
      },
      {
        label: 'Mob - 4G Handset Base (in Nos)',
        rowSpan: 1,
        colSpan: 3 * totalMonths,
      },
      // {
      //   label: 'OG MoUs (in Nos)',
      //   rowSpan: 1,
      //   colSpan: 3 * totalMonths,
      // },
      // {
      //   label: 'Data Usage (Mb)',
      //   rowSpan: 1,
      //   colSpan: 3 * totalMonths,
      // },
      {
        label: 'Mob - Data Usage  (Mb)/ Sub',
        rowSpan: 1,
        colSpan: 3 * totalMonths,
      },
      // IOT
      { label: 'IOT - Gross Net (in Nos)', rowSpan: 1, colSpan: totalMonths },
      { label: 'IOT - Churn (in Nos)', rowSpan: 1, colSpan: totalMonths },
      { label: 'IOT - Net adds (in Nos)', rowSpan: 1, colSpan: totalMonths },
      {
        label: 'IOT - Active Subs Base (in Nos)',
        rowSpan: 1,
        colSpan: totalMonths,
      },
      {
        label: 'IOT - Suspended Subs Base (in Nos)',
        rowSpan: 1,
        colSpan: totalMonths,
      },
      {
        label: 'IOT - Billed Net Amt (W/o Tax) (Rs. Cr)',
        rowSpan: 1,
        colSpan: totalMonths,
      },
      // { label: 'Waivers', rowSpan: 1, colSpan: totalMonths },
      { label: 'IOT - Adjustments (Rs. Cr)', rowSpan: 1, colSpan: totalMonths },
      {
        label: 'IOT - Outstanding Amt (Rs. Cr)',
        rowSpan: 1,
        colSpan: totalMonths,
      },
      // FLD
      { label: 'FLD - OB (Rs. Cr)', rowSpan: 1, colSpan: totalMonths },
      {
        label: 'FLD - OB No. of Orders (in Nos)',
        rowSpan: 1,
        colSpan: totalMonths,
      },
      { label: 'FLD - Churn AOV (Rs. Cr)', rowSpan: 1, colSpan: totalMonths },
      {
        label: 'FLD - Churn No. of Links (Count)',
        rowSpan: 1,
        colSpan: totalMonths,
      },
      {
        label: 'FLD - Commisioning (Rs. Cr)',
        rowSpan: 1,
        colSpan: totalMonths,
      },
      {
        label: 'FLD - Active Links (in Nos)',
        rowSpan: 1,
        colSpan: totalMonths,
      },
      {
        label: 'FLD - Suspended Links (in Nos)',
        rowSpan: 1,
        colSpan: totalMonths,
      },
      {
        label: 'FLD - Billed Net Amt (W/o Tax) (Rs. Cr)',
        rowSpan: 1,
        colSpan: totalMonths,
      },
      // { label: 'Waivers', rowSpan: 1, colSpan: totalMonths },
      { label: 'FLD - Adjustments (Rs. Cr)', rowSpan: 1, colSpan: totalMonths },
      {
        label: 'FLD - Outstanding Amt (Rs. Cr)',
        rowSpan: 1,
        colSpan: totalMonths,
      },
      // FLV
      { label: 'FLV - OB (Rs. Cr)', rowSpan: 1, colSpan: totalMonths },
      { label: 'FLV - OB No. of Orders', rowSpan: 1, colSpan: totalMonths },
      { label: 'FLV - Active Links', rowSpan: 1, colSpan: totalMonths },
      { label: 'FLV - Suspended Links', rowSpan: 1, colSpan: totalMonths },
      {
        label: 'FLV - Billed Net Amt (W/o Tax)',
        rowSpan: 1,
        colSpan: totalMonths,
      },
      // { label: 'Waivers', rowSpan: 1, colSpan: totalMonths },
      { label: 'FLV - Adjustments', rowSpan: 1, colSpan: totalMonths },
      { label: 'FLV - Outstanding Amt', rowSpan: 1, colSpan: totalMonths },
      // CLOUD
      {
        label: 'CLoud - Cloud Gross ( Lic.)',
        rowSpan: 1,
        colSpan: totalMonths,
      },
      {
        label: 'Cloud - Cloud Churn ( Lic.)',
        rowSpan: 1,
        colSpan: totalMonths,
      },
      { label: 'Cloud - Cloud Net ( Lic.)', rowSpan: 1, colSpan: totalMonths },
      {
        label: 'Cloud - Cloud OB ( In Rs Cr.)',
        rowSpan: 1,
        colSpan: totalMonths,
      },
      {
        label: 'Cloud - Billed Net Amt (W/o Tax) (Rs. Cr)',
        rowSpan: 1,
        colSpan: totalMonths,
      },
      // { label: 'Waivers', rowSpan: 1, colSpan: totalMonths },
      {
        label: 'Cloud - Adjustments (Rs. Cr)',
        rowSpan: 1,
        colSpan: totalMonths,
      },
      {
        label: 'Cloud - Outstanding Amt (Rs. Cr)',
        rowSpan: 1,
        colSpan: totalMonths,
      },
      // CTLRS OB
      { label: 'COLO - COLO OB (Rs. Cr)', rowSpan: 1, colSpan: totalMonths },
      {
        label: 'COLO - Billed Net Amt (W/o Tax) (Rs. Cr)',
        rowSpan: 1,
        colSpan: totalMonths,
      },
      // { label: 'Waivers', rowSpan: 1, colSpan: totalMonths },
      {
        label: 'COLO - Adjustments (Rs. Cr)',
        rowSpan: 1,
        colSpan: totalMonths,
      },
      {
        label: 'COLO - Outstanding Amt (Rs. Cr)',
        rowSpan: 1,
        colSpan: totalMonths,
      },
    ]
    let thirdHeader = [
      { label: 'All Products', rowSpan: 1, colSpan: totalMonths },
      ...Array(18) //18
        .fill()
        .map((item, i) => {
          if (i % 3 === 0) {
            return { label: 'COCP', rowSpan: 1, colSpan: totalMonths }
          } else if (i % 3 === 1) {
            return { label: 'IOIP', rowSpan: 1, colSpan: totalMonths }
          } else {
            return { label: 'Total', rowSpan: 1, colSpan: totalMonths }
          }
        }),
      ...Array(1)
        .fill()
        .map(i => ({ label: 'COCP', rowSpan: 1, colSpan: totalMonths })),
      ...Array(1)
        .fill()
        .map(i => ({ label: 'OVERALL', rowSpan: 1, colSpan: totalMonths })),
      ...Array(15)
        .fill()
        .map((item, i) => {
          if (i % 3 === 0) {
            return { label: 'COCP', rowSpan: 1, colSpan: totalMonths }
          } else if (i % 3 === 1) {
            return { label: 'IOIP', rowSpan: 1, colSpan: totalMonths }
          } else {
            return { label: 'Total', rowSpan: 1, colSpan: totalMonths }
          }
        }),
      ...Array(7)
        .fill()
        .map(i => ({ label: 'Overall', rowSpan: 1, colSpan: totalMonths })),
      { label: 'Overall', rowSpan: 1, colSpan: totalMonths },
      ...Array(22) //28
        .fill()
        .map(i => ({ label: 'Overall', rowSpan: 1, colSpan: totalMonths })),
    ]
    let fourthHeader = Array(66 * totalMonths) //72
      .fill()
      .map((item, i) => {
        if (totalMonths > 1) {
          let months = []
          if (queryFilters.selectedPeriodType?.value === 17) {
            months = quaters
          } else if (queryFilters.selectedPeriodType?.value === 6) {
            for (let i = 0; i < 3; i++) {
              if (queryFilters.selectedPeriod?.value - i >= 1) {
                months.push(
                  monthsName[
                    queryFilters.selectedPeriod?.value - i
                  ]?.toUpperCase()
                )
              } else {
                months.push(
                  monthsName[
                    queryFilters.selectedPeriod?.value + (12 - i)
                  ]?.toUpperCase()
                )
              }
            }
            months.reverse()
          } else {
            months = monthRange[queryFilters.selectedPeriod?.value - 1]
          }
          if (i % totalMonths === 0) {
            return { label: months?.[0], rowSpan: 1, colSpan: 1 }
          } else if (i % totalMonths === 1) {
            return { label: months?.[1], rowSpan: 1, colSpan: 1 }
          } else if (i % totalMonths === 2) {
            return { label: months?.[2], rowSpan: 1, colSpan: 1 }
          } else if (i % totalMonths === 3) {
            return { label: months?.[3], rowSpan: 1, colSpan: 1 }
          }
        } else {
          let month = monthsName[queryFilters.selectedPeriod?.value]
          return { label: month, rowSpan: 1, colSpan: 1, minWidth: 200 }
        }
      })
    // console.log({ fourthHeader, secondHeader, thirdHeader });
    let tableHeader = (
      <>
        <TR>
          <TH rowSpan={4} justify={'center'}>
            #
          </TH>
          {firstHeader.map((head, i) => (
            <TH
              rowSpan={head.rowSpan}
              colSpan={head.colSpan}
              key={i}
              classes={head.className}
              minWidth={head.minWidth}
              justify={'center'}
            >
              {head.label}
            </TH>
          ))}
        </TR>
        <TR>
          {secondHeader.map((head, i) => (
            <TH
              rowSpan={head.rowSpan}
              colSpan={head.colSpan}
              key={i}
              justify={'center'}
            >
              {head.label}
            </TH>
          ))}
        </TR>
        <TR>
          {thirdHeader.map((head, i) => (
            <TH
              rowSpan={head.rowSpan}
              colSpan={head.colSpan}
              key={i}
              justify={'center'}
            >
              {head.label}
            </TH>
          ))}
        </TR>
        <TR>
          {fourthHeader.map((head, i) => (
            <TH
              rowSpan={head.rowSpan}
              colSpan={head.colSpan}
              key={i}
              justify={'center'}
              minWidth={head.minWidth}
            >
              {head.label}
            </TH>
          ))}
        </TR>
      </>
    )
    return tableHeader
  }

  const makeTableData = () => {
    let arr = []
    let months = []
    let totalMonths = noOfmonths[queryFilters.selectedPeriodType?.value] || 1
    if (totalMonths > 1) {
      if (queryFilters.selectedPeriodType?.value === 17) {
        months = quaters
      } else if (queryFilters.selectedPeriodType?.value === 6) {
        for (let i = 0; i < 3; i++) {
          if (queryFilters.selectedPeriod?.value - i >= 1) {
            months.push(
              monthsName[queryFilters.selectedPeriod?.value - i]?.toUpperCase()
            )
          } else {
            months.push(
              monthsName[
                queryFilters.selectedPeriod?.value + (12 - i)
              ]?.toUpperCase()
            )
          }
        }
        months.reverse()
      } else {
        months = monthRange[queryFilters.selectedPeriod?.value - 1]
      }
    } else {
      months = [monthsName[queryFilters.selectedPeriod?.value]?.toUpperCase()]
    }
    if (Object.keys(accounts_view).length) {
      Object.values(accounts_view).forEach((item, i) => {
        let obj = {}
        obj['S no.'] = i + 1
        obj['Segment'] = item.segment
        obj['acc_code'] = item.account_code
        obj['acc_name'] = item.account_name
        obj['acc_manager'] = item.account_manager
        obj['circle_name'] = item.circle_code
        ;[
          'total_billed_amt',
          'gross_net_cocp_mob',
          'gross_net_ioip_mob',
          'gross_net_total_mob',
          'churn_cocp_mob',
          'churn_ioip_mob',
          'churn_total_mob',
          'netadds_cocp_mob',
          'netadds_ioip_mob',
          'netadds_total_mob',
          'sub_base_active_cocp_mob',
          'sub_base_active_ioip_mob',
          'sub_base_active_total_mob',
          'sub_base_suspended_cocp_mob',
          'sub_base_suspended_ioip_mob',
          'sub_base_suspended_total_mob',
          'billed_net_amt_cocp_mob',
          'billed_net_amt_ioip_mob',
          'billed_net_amt_total_mob',
          'adjustment_cocp_mob',
          // 'waivers_mob',
          'outstanding_amt_total_mob',
          'billed_arpu_cocp_mob',
          'billed_arpu_ioip_mob',
          'billed_arpu_total_mob',
          'handset_fourg_cocp_mob',
          'handset_fourg_ioip_mob',
          'handset_fourg_total_mob',
          // 'og_mous_cocp_mob',
          // 'og_mous_ioip_mob',
          // 'og_mous_total_mob',
          // 'data_usage_cocp_mob',
          // 'data_usage_ioip_mob',
          // 'data_usage_total_mob',
          'data_usage_mb_cocp_mob',
          'data_usage_mb_ioip_mob',
          'data_usage_mb_total_mob',
          'gross_net_total_iot',
          'churn_total_iot',
          'netadds_total_iot',
          'sub_base_active_total_iot',
          'sub_base_suspended_total_iot',
          'billed_net_amt_total_iot',
          // 'waivers_iot',
          'adjustment_total_iot',
          'outstanding_amt_total_iot',
          'ob_in_cr_fld',
          'ob_in_order_fld',
          'churn_aov_in_cr_fld',
          'churn_no_of_links_fld',
          'commisioning_fld',
          'active_link_fld',
          'suspended_link_fld',
          'billed_net_amount_fld',
          // 'waviers_fld',
          'adjustments_fld',
          'outstanding_amt_fld',
          'ob_in_cr_flv',
          'ob_in_order_flv',
          'active_link_flv',
          'suspended_link_flv',
          'billed_net_amount_flv',
          // 'waviers_flv',
          'adjustments_flv',
          'outstanding_amt_flv',
          'cloud_gross_in_lic',
          'cloud_churn_in_lic',
          'cloud_net_in_lic',
          'cloud_ob_in_cr',
          'cloud_billed_net_amount',
          // 'cloud_waviers',
          'cloud_adjustments',
          'cloud_outstanding_amt',
          'ctrls_ob_in_cr',
          'ctrls_billed_net_amount',
          // 'ctrls_waviers',
          'ctrls_adjustments',
          'ctrls_outstanding_amt',
        ].forEach(key => {
          months.forEach(mnth => {
            obj[`${key}_${mnth}`] = item[mnth]?.[key] || '-'
          })
        })
        arr.push(obj)
      })
    }
    return arr
  }

  const renderData = () => {
    let data = makeTableData()
    return data.map((item, i) => (
      <>
        <TR key={i}>
          {Object.values(item).map((val, j) => {
            let valFloat = val
            if (j !== 0) {
              valFloat = parseFloat(val)?.toFixed(2)
            }
            if (!val || isNaN(valFloat)) return <TD key={j}>{val}</TD>
            return (
              <TD key={j}>
                <Tooltip label={numberWithCommas(val)} aria-label="Exact Value">
                  <span tabIndex={0}>{numberWithCommas(valFloat)}</span>
                </Tooltip>
              </TD>
            )
          })}
        </TR>
        {data.length - 1 === i && isLoading ? (
          <TR>
            <Flex my="20px" justify="center">
              <PropagateLoader color="#718096" />
            </Flex>
          </TR>
        ) : null}
      </>
    ))
  }

  const getHeaderForExport = (header, xstep = 0, ystep = 0) => {
    let objColumn = []
    header.forEach(item => {
      objColumn.push({ title: item.title })
      if (item.colspan > 1)
        objColumn.push(...Array(item.colspan - 1).fill({ title: '' }))
    })
    let obj = {
      xSteps: xstep,
      ySteps: ystep,
      columns: objColumn.map(item => {
        return { title: item.title }
      }),
      data: [
        objColumn.map(item => {
          return { value: '' }
        }),
      ],
    }
    return obj
  }

  const getDataForExport = (header, accountData, xstep = 0, ystep = 0) => {
    let obj = {
      xSteps: xstep,
      ySteps: ystep,
      columns: header.map(item => ({ title: item.title })),
      data: accountData.map(item => {
        return header.map(header => ({
          value: numberWithCommas(item[header.value]) || '',
        }))
      }),
    }
    return obj
  }

  const createExcel = () => {
    const result = []
    const accountData = makeTableData()
    if (accountData.length === 0) return
    let totalMonths = 1
    if (queryFilters.selectedPeriodType?.value === 17) {
      totalMonths = 4
    } else {
      totalMonths = noOfmonths[queryFilters.selectedPeriodType?.value] || 1
    }

    result.push(getDataForExport(firstSetHeader, accountData, 0, 3))

    result.push(
      getHeaderForExport(
        secondSetRowOneHeader(totalMonths),
        firstSetHeader.length,
        -accountData.length - 4
      )
    )
    result.push(
      getHeaderForExport(
        secondSetRowTwoHeader(totalMonths),
        firstSetHeader.length,
        -1
      )
    )
    result.push(
      getHeaderForExport(
        secondSetRowThirdHeader(totalMonths),
        firstSetHeader.length,
        -1
      )
    )

    result.push(
      getDataForExport(
        secondSetRowFourthHeader(
          totalMonths,
          queryFilters.selectedPeriod?.value,
          queryFilters.selectedPeriodType?.value
        ),
        accountData,
        firstSetHeader.length,
        -1
      )
    )
    return result
  }

  return 1 !== 1 ? (
    <Stack>
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
    </Stack>
  ) : (
    <>
      <VStack alignItems="flex-start" justifyContent={'flex-start'}>
        <Flex
          mb={0}
          p={3}
          alignItems="flex-start"
          w="fit-content"
          position="sticky"
          left={0}
        >
          {lastUpdate ? (
            <Text color={localStorage.getItem('color')} fontWeight={600} lineHeight="2.8em" mr={3}>
              Last Updated at: {lastUpdate}
            </Text>
          ) : null}

          <div
            style={
              Object.keys(accounts_view).length === 0
                ? {
                    cursor: 'not-allowed',
                  }
                : {}
            }
          >
            <div
              style={
                Object.keys(accounts_view).length === 0
                  ? {
                      pointerEvents: 'none',
                      opacity: '0.4',
                    }
                  : {}
              }
            >
              <ExcelFile
                filename={`Account_View_Report_${
                  queryFilters.selectedCreator?.value
                }_${queryFilters.selectedPeriodType?.value || ''}_${
                  queryFilters.selectedPeriod?.value || ''
                }`}
                element={
                  <Button mr={2}>
                    <Icon as={BiDownload} w={6} h={6} mr={1} />
                    <Text fontWeight="bold" color={localStorage.getItem('color')}>
                      Export Data
                    </Text>
                  </Button>
                }
              >
                <ExcelSheet
                  dataSet={createExcel()}
                  name="Organization"
                ></ExcelSheet>
              </ExcelFile>
            </div>
          </div>
        </Flex>
      </VStack>
      <VStack spacing={8}>
        <DrillDownTable
          theadings={renderHeadings()}
          tdata={renderData()}
          isLoading={isLoading}
        />
      </VStack>
    </>
  )
}

export default AccountView
